<style scope lang="scss">
  .comp-sort-box {
    max-width: 120px;
    .el-icon-minus:before {
        font-size: 16px;
        content: "\e6e6";
    }
    .el-icon-plus:before {
        font-size: 16px;
        content: "\e6eb";
    }
    .el-input-number--mini {
        max-width: 120px;
    }
  }
</style>

<template>
  <div class="comp-sort-box">
    <div class="sort-box">
      <template v-if="data.sort > 0">
          <el-input-number v-model="data.sort" :min="1" size="mini" placeholder="排序未配置" @change="(v) => { setSort(v, data) }"></el-input-number>
      </template>
      <template v-else>
          <el-input-number v-model="data.sort" :min="null" :controls="false" size="mini" placeholder="排序未配置" @change="(v) => { setSort(v, data) }"></el-input-number>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompSort",
  props: {
    data: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  watch: {
    
  },
  mounted() {
    
  },
  data() {
    return {}
  },
  methods: {
    setSort(val, row) {
      this.$emit("change", { val, row })
    }
  }
}
</script>
