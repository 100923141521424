<style scoped lang="scss">
.page-assets-user-type {
    /deep/ .el-tree-node__content {
        height: auto;
    }
    .categrory-tree {
        width: 100%;
        overflow: auto;
    }
    .categrory-titles {
        width: calc(100% - 24px);
        min-width: 1000px;
        padding: 10px 0 10px 24px;
        border-bottom: 1px solid #eee;
        color: #909399;
        font-weight: 500;
        .name {
            width: 60%;
            float: left;
        }
        .sorts {
            width: 25%;
            float: left;
        }
        .buttons {
            width: 15%;
            float: right;
            text-align: center;
        }
    }
    .categrory-table {
        width: 100%;
        min-width: 1000px;
        padding: 6px 0;
        border-bottom: 1px dashed #eee;
        white-space: normal;
        .name {
            width: 60%;
            padding-top: 5px;
            float: left;
        }
        .sorts {
            width: 25%;
            float: left;
            /deep/ .sort-input {
                max-width: 120px;
                .el-icon-minus:before {
                    font-size: 16px;
                    content: "\e6e6";
                }
                .el-icon-plus:before {
                    font-size: 16px;
                    content: "\e6eb";
                }
                .el-input-number--mini {
                    max-width: 120px;
                }
            }
        }
        .buttons {
            width: 15%;
            float: right;
            text-align: center;
        }
    }
}

</style>
<template>
    <div class="page-assets-user-type">
        <div class="mrgb5">
            <el-button class="mrgb5" type="primary" size="medium" @click="_edit()"><i class="fa fa-plus"></i> 新增</el-button>
            <div class="clearb"></div>
        </div>
        <div class="categrory-tree">
            <div class="categrory-titles">
                <div class="name">【ID】分类名称</div>
                <div class="sorts" v-show="false">排序</div>
                <div class="buttons">操作</div>
                <div class="clearb"></div>
            </div>
            <el-tree :ref="refCategorys" :data="allCategorys" :props="defaultProps" :indent="10" :default-expanded-keys="expandKeys"
                :filter-node-method="filterCategory" :highlight-current="true" node-key="id" @node-expand="nodeOpen" :auto-expand-parent="false"
                @node-collapse="nodeClose">
                <div class="categrory-table" slot-scope="{ data }">
                    <div class="name">
                        <span class="col9">【{{ data.id }}】</span>
                        {{ data.name }}
                    </div>
                    <div class="sorts" @click.stop="" v-show="false">
                        <el-tag type="primary" size="small">{{ data.sort }}</el-tag>
                        <sort :data="data" @change="(item) => { setSort(item.val, item.row) }" v-if="false"></sort>
                    </div>
                    <div class="buttons">
                        <el-button type="parmary" size="mini" @click.stop="() => _edit(data)">编辑</el-button>
                        <el-button type="parmary" size="mini" @click.stop="() => _delete(data)">删除</el-button>
                    </div>
                    <div class="clearb"></div>
                </div>
            </el-tree>
        </div>

        <!--编辑-->
        <edit :ref="refCategoryEdit" @refreshData="getRefreshData"></edit>
    </div>
</template>

<script>
import * as funInfomation from "@/api/information"
import edit from "./components/typeEdit"
import sort from "@/components/CompSort"
export default {
    name: "pageAssetsUserType",
    components: { edit, sort },
    data(){
        return {
            tableData: [],
            refCategoryEdit: "refAssetsToEdit",
            allCategorys: [],
            refCategorys: "refAssetsList",
            defaultProps: {
                id: "id",
                children: 'children',
                label: 'name'
            },
            expandKeys: []
        }
    },
    mounted() {
        this.getDataList()
    },
    methods:{
        open() {
            this.getDataList()
        },
        getDataList() {
            window.$common.fullLoading()
            funInfomation.getAssetUserCategory().then(res => {
                window.$common.closeFullLoading()
                this.allCategorys = this.parseCategoryTree(res)
            })
        },
        parseCategoryTree(datas) {
            let trees = []
            for(let i in datas) {
                let temp = datas[i]
                if (datas[i].children && datas[i].children.length > 0) {
                    temp.children = this.parseCategoryTree(datas[i].children)
                } else {
                    delete temp.children
                }
                trees.push(temp)
            }
            return trees
        },
        filterCategory(value, data) {
            if (!value) return true
            return data.name.indexOf(value.toLowerCase()) !== -1
        },
        nodeOpen(data) {
            // 树展开记录展开的id
            let key = data.id
            let index = this.expandKeys.findIndex(x => {
                return x == key
            })
            if (index == -1) {
                this.expandKeys.push(key)
            }
            console.log(this.expandKeys)
        },
        nodeClose(data) {
            // 关闭删除id
            let key = data.id
            this.expandKeys = this.expandKeys.filter(x => {
                return x != key
            })
            console.log(this.expandKeys)
        },
        getRefreshData() {
            this.getDataList()
        },
        // 设置排序
        setSort(val, row) {
            /* if (val > 0) {
                funInfomation.categrorySetSort({id: row.id, sort: val || "" }).then(res => {
                    this.getDataList()
                })
            } else {
                funInfomation.categrorySetSort({id: row.id, sort: null }).then(res => {
                    this.getDataList()
                })
            } */
        },
        _edit(row) {
            this.$refs[this.refCategoryEdit].open(row)
        },
        _delete(row) {
            this.confirm("确定要删除当前分类吗？").then(()=>{
                window.$common.fullLoading()
                funInfomation.delAssetUserCategory({ id: row.id }).then(res => {
                    this.commonSuccess()
                })
            })
        },
        commonSuccess(val) {
            let tips = val || "操作成功!"
            this.alert(tips).then(() => {
                window.$common.closeFullLoading()
                this.getDataList()
            })
        }
    }
}
</script>
